import React, { Component } from 'react'
import Navbar from "../components/navbar"
import {BsClock}   from "react-icons/bs"
import {IoLocationOutline,IoCallOutline}from "react-icons/io5"
import Footer from "../components/footer"
import emailjs  from "emailjs-com"
import Helmet from 'react-helmet'
import Swal from "sweetalert2"
export default class contact extends Component {
    handleFormSubmit=(e)=>{
    
        e.preventDefault();
      
      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID,process.env.REACT_APP_EMAILJS_TEMPLATE_ID,e.target,process.env.REACT_APP_EMAILJS_USER_ID).then(res=>{

if(res.status===200){
    Swal.fire({
        icon:"success",
       
        title:"your request has been submitted, will get back to you soon",
        confirmButtonColor:"#38a3a5",
     

    })

}
    })
    }
    render() {
        return (
            <>
                <Navbar />
                <Helmet>
             
             <title>Parvathi Tutorials | Contact</title>
             
         </Helmet>
                <main >
                    <section className="contact" id="info">
                        <h2 className="contact-heading">
                            For more information contact us
                        </h2>
                        <div className="contact-details">
                            <div className="contact-details-row">
                                <div className="contact-details-header">
                                <span><IoLocationOutline/></span>
                                    <h3>Address</h3>
                                    <p>  Parvathi Tutorials, Mutharayana Nagar, Nagdevanahalli, Bengaluru, Karnataka 560056</p>
                                </div>
                            </div>
                            <div className="contact-details-row">
                                <div className="contact-details-header">
                                <span><IoCallOutline/></span>
                                    <h3>contact</h3>
                                    <p>
                                  Mobile:  <a href="tel:+919731796570">+91-9731796570</a> <br />
                                  Mobile:        <a href="tel:+919738380832">+91-9738380832</a>  <br />
                                    Email:                   <a href="mailto:parvathitutorials@gmail.com">parvathitutorials@gmail.com</a>

                                    </p>
                                </div>
                            </div>
                            <div className="contact-details-row">
                                <div className="contact-details-header">
                                <span><BsClock/></span>
                                    <h3>Timing</h3>
                                    <p>
                                    Monday - Friday: 09:00 - 20:00 <br />
Sunday & Saturday: 10:30 - 22:00 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <h2 className="contact-heading mt-big f-large">
                            Fill out this form for booking a consultant advising session.
                            </h2>
                        </div >
                        <form className="contact-form" onSubmit={this.handleFormSubmit}>
                            <div className="contact-row"  >
                                <input type="text" name="user_name" className="contact-input" required placeholder="Name *"/>
                                <input type="text" name="user_phone" className="contact-input" pattern="[1-9]{1}[0-9]{9}" required placeholder="phone *"/>
                            </div>
                            <div className="contact-col">
                                <input type="email"  name="user_email" className="contact-input" required placeholder="Email *" />
                         
                            </div>
                            <div className="contact-col">
                                <input type="text" name="subject" className="contact-input" required placeholder="Subject *" />
                         
                            </div>
                            <div className="contact-col">
                               <textarea name="message"  className="contact-input" required placeholder="message *" cols="30" rows="10"></textarea>
                            </div>
                            <button className=" button contact-button" type="submit">Submit</button>
                        </form>
                    </section>
                </main>
                <Footer/>
            </>
        )
    }
}