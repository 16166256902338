import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./css/style.css";
import Home from "./page/home";
import About from "./page/about"
import Specialization from "./page/specialization"
import Gallery from "./page/gallery"
import Contact from "./page/contact"
function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/specialization" component={Specialization} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
