import React, { Component } from 'react'
import Logo from "../asset/logo.svg"
import { NavLink } from "react-router-dom";
export default class navbar extends Component {


    checkActive = (match, location) => {
        console.log(location)

        if (!location) return false;

        const { pathname } = location;

        if (pathname === "/") {
            document.getElementById("home").classList.add("active")
            return false
        };

    }

    componentDidMount() {
        document.addEventListener("DOMContentLoaded", function () {
            window.addEventListener('scroll', function () {
                if (window.scrollY > 50) {
                    document.getElementById('navbar').classList.add('fixed-top');
                    document.getElementById("fix-scroll").classList.add("fix-scroll")
                    var navbar_height = document.querySelector('.navbar').offsetHeight;
                    document.body.style.paddingTop = navbar_height + 'px';

                } else {
                    document.getElementById('navbar').classList.remove('fixed-top');
                    // remove padding top from body
                    document.body.style.paddingTop = '0';
                    document.getElementById("fix-scroll").classList.remove("fix-scroll")
                }
            });
        });
    }
    render() {
        return (
            <nav>
                <div className="navbar" id="navbar">
                    <div className="navbar-logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="navbar-container">
                        <ul>
                            <li className="navbar-link">
                                <NavLink to="/" id="home" exact activeClassName="active" >Home</NavLink>
                            </li>
                            <li className="navbar-link">
                                <NavLink activeClassName="active" exact to="/about">About</NavLink>
                            </li>
                            <li className="navbar-link">
                                <NavLink activeClassName="active" exact to="/specialization">Specialization</NavLink>
                            </li>
                            <li className="navbar-link">
                                <NavLink activeClassName="active" exact to="/gallery">Gallery</NavLink>
                            </li>
                            <li className="navbar-link">
                                <NavLink activeClassName="active" exact to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                   <div className="hamburger">
                      
                            <input type="checkbox" id="mobile-nav" />
                            <label htmlFor="mobile-nav">
                                <span></span>
                        </label>
                        <div className="mobile-nav-banner"></div>
                        <div className="mobile-nav-container" id="fix-scroll">
                        <ul>
                            <li className="navbar-link">
                                <NavLink to="/" id="home" exact activeClassName="active" >Home</NavLink>
                            </li>
                            <li className="navbar-link">
                                <NavLink activeClassName="active" exact to="/about">About</NavLink>
                            </li>
                            <li className="navbar-link">
                                <NavLink activeClassName="active" exact to="/specialization">Specialization</NavLink>
                            </li>
                            <li className="navbar-link">
                                <NavLink activeClassName="active" exact to="/gallery">Gallery</NavLink>
                            </li>
                            <li className="navbar-link">
                                <NavLink activeClassName="active" exact to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                        </div>
                   </div>

                </div>
            </nav>
        )
    }
}
