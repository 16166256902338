import React, { Component } from "react";
import { IoCall } from "react-icons/io5";
import { FaLocationArrow } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SiFacebook } from "react-icons/si";
import { RiInstagramFill } from "react-icons/ri";
import { AiFillTwitterCircle,AiFillHeart,AiOutlineCopyright } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import Logo from "../asset/logo-footer.svg"
export default class footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-list">
            <div className="footer-list-content">
              <h2>Find us</h2>
              <p>
                Get our excellent coaching. Learn better, gain extra!
              </p>
              <ul>
                <li>
                  <span>
                    <FaLocationArrow />
                  </span>
                  Parvathi Tutorials, Mutharayana Nagar, Nagdevanahalli, Bengaluru, Karnataka 560056
                </li>

                <li>
                  <span>
                    <IoCall />
                  </span>
                  <a href="tel:+919731796570">+91-9731796570</a>
                  <br />
                  <span>
                    <IoCall />
                  </span> 
                  <a href="tel:+919738380832">+91-9738380832</a> 
                </li>
                <li>
                  <span>
                    <MdEmail />
                  </span>
                  <a href="mailto:parvathitutorials@gmail.com">parvathitutorials@gmail.com</a>
                
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-list">
            <h2>Quick links</h2>
            <ul>
              <li>
                <NavLink to="/" id="home" exact activeClassName="active">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" exact to="/about">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" exact to="/specialization">
                  Specialization
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" exact to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-list ">
            <h2>Social media handles</h2>
            <ul  className="social">
              <li>
                <a href="https://facebook.com/Parvathi.Tutorials/">
                  {" "}
                 
                    <span>
                        <SiFacebook />
                    </span> 
                    
                 
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/parvathitutorials">
                  <span>
                      <RiInstagramFill />
                  </span> 
                </a>
              </li>
           
            </ul>
            <img src={Logo} alt="logo" className="footer-logo" />
          </div>
         
        </div>
        <div className="footer-credits">
            <p>
            Copyright <AiOutlineCopyright /> 2021  <span>Parvathi tutorials</span> | Designed With <AiFillHeart /> by <a href="http://codingexpress.in/">CodingExpress</a>
            </p>
        </div>
      </footer>
    );
  }
}
