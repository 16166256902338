import React, { Component } from 'react'

export default class card extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className="student-testimonial ">
                        <div className="student-testimonial-container">
                        <img src={this.props.img} alt="hero" className="student-testimonial-hero" />
                    <p className="student-testimonial-description">
                        {this.props.des}
                    </p>
                    <h3 className="student-testimonial-name">{this.props.name}</h3>
                    <p className="student-testimonial-grade">
                        {this.props.cgpa}
                    </p>
                </div>
                </div>
                    
        )
    }
}
