import React, { Component } from "react";
import Banner_2x from "../asset/home/banner-hero-1x.jpg";
import Test from "../asset/home/dots.png";
import { BsArrowRight, BsCheckCircle } from "react-icons/bs";
import Card from "../components/card";
import Carousel from "react-elastic-carousel";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Carousel2 from "../asset/home/carousel/carousel2.jpg";
import Carousel1 from "../asset/home/carousel/carousel1.jpg";
import Carousel3 from "../asset/home/carousel/carousel3.png";
import Carousel4 from "../asset/home/carousel/carousel4.png";
import Carousel5 from "../asset/home/carousel/carousel5.png";
import Carousel6 from "../asset/home/carousel/carousel6.png";
import Banner from "../asset/home/banner.jpg";
import Default from "../asset/home/carousel/default.svg";
import School_1 from "../asset/home/School_1.jpg";
import School_2 from "../asset/home/School_2.jpg";
import School_3 from "../asset/home/School_3.jpg";
import School_4 from "../asset/home/School_4.jpg";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
export default class home extends Component {
  list = [
    {
      img: Carousel1,
      des: `I had a fantastic learning experience with Parvathi Tutorials.The individual attention and proper assistance helped me overcome the difficulties faced in understanding the concepts  by perceiving different perspectives of the same subject. Studying here made me able to explore new learning style.`,
      name: `Karthik`,
      cgpa: `10/10 cgpa`,
    },
    {
      img: Carousel2,
      des: `I am very delighted to have taken up math class at Parvathi Tutorial at the right time. I found their teaching to be well-structured and proficient. I was able to get a grip on the concepts and apply them effectively. My teachers Mr.Bhargav and Mr. Sharath Kumar always motivated me and were more than happy to address my doubts anytime.`,
      name: `Anirudh`,
      cgpa: `10/10 cgpa`,
    },
    {
      img: Carousel3,
      des: `I am proud to say that I've been tutored by bhargav sir during my SSLC. He is indeed a great teacher who teaches his students with a lot of enthusiasm, encourages them and motivates them for scoring higher marks and understanding concepts. As a math student, I think I've made more progress under him. A great friend and nonetheless, a great teacher!! `,
      name: `Anand`,
      cgpa: `97%`,
    },
    {
      img: Carousel4,
      des: `I have had a good time, meeting new friends throughout my time here and growing as a person both academically and in my personal life. People have seen a lot of positive differences after joining Parvathi tutorial. Thank you Parvathi tutorial to bring out the best in me.`,
      name: `SUMIT`,
      cgpa: `9.6/10 cgpa`,
    },
    {
      img: Carousel5,
      des: `I was a student of Parvathi tutorial in 2016. In my experience I have received the greatest knowledge from the faculty, not only in academics but they also motivated me to give my best and guided me throughout the journey. I am really thankful to the faculty and our dear Parvathi ma’am for such a wonderful guidance. `,
      name: `Sneha`,
      cgpa: `10/10 cgpa`,
    },
    {
      img: Carousel6,
      des: `The teaching has been a very satisfying aspect of Parvathi Tutorials. Mathematics was not just about formulas and solutions, tricks they though was very useful. I thank every tecaher who made us think out of the box, which developed interests in Mathematics and Science.`,
      name: `Yashas`,
      cgpa: `9.8/10 cgpa`,
    },
    {
      img: Default,
      des: `By the time i joined Parvathi Tutorials it was late still the teachers managed to help me. I learnt different methods to solve a problem. After each chapter, we would take up tests to realize how much we had learnt and how much more we had to about the chapter. It helped a lot to concentrate on the chapters which were difficult. They help us think out of the box too.`,
      name: `Gagana`,
      cgpa: `96%`,
    },

    {
      img: Default,
      des: `I managed to score better in my 10th CBSE exams only with their coaching, they not only helped me through studies but also helped me in improving my social skills. Teachers learned student’s difficulty and used different method to teach them.`,
      name: `Sukruthi`,
      cgpa: `9.4/10 cgpa`,
    },

    {
      img: Default,
      des: `It was great joining Parvathi Tutorials. Individual importance for a student is given here. Teachers are flexible and clear all doubts at that very instant. Finally a best place for gaining knowledge. I assure you that you see a lot of improvement in you after joining here.`,
      name: `Srivatsa`,
      cgpa: `94%`,
    },
  ];

  render() {
    return (
      <>
        <Navbar />
        <Helmet>
          <title>Parvathi Tutorials | Home</title>
        </Helmet>
        <header>
          <div className="banner">
            <div className="banner-color"></div>

            <img src={Banner} alt="" className="banner-img" />
            <img src={Test} alt="" className="banner-polka" />
            <div className="banner-typography">
              <h2 className="banner-title">
              Our teaching  <span>philosophy</span>
              </h2>
              <p className="banner-description">
              Is built on engaging students in more participatory ways with subjects.
Our classroom is available to students of all levels, and we strive to help students comprehend subjects via living examples.
We make certain that each student is individually assessed and taught properly.
We have well-equipped laboratories for putting the principles learned on paper into practise.
We have a regular assignment structure in place to assist students in revising the ideas learned in class.
              </p>
              <a className="banner-button button" href="#courses-offered" >know more</a>
            </div>
          </div>
        </header>
        <main>
          <section className="pt-bigX pb-bigX backdrop ">
            <div className="entitlement">
              <h1 className="entitlement-heading">What makes us different?</h1>
              <h3 className="entitlement-description">
                Our teachers have made the method of teaching interactive,
                with live examples. They coach and facilitate
                students with comprehensive materials. Our way of teaching has
                always involved students as a part of teaching and learning.
              </h3>
            </div>
            <div className="learning-methods">
              <div className="learning-methods-container">
                <div className="learning-methods-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M21,40v5h22l0.001-5.107C49,36.195,53,29.564,53,22  c0-11.598-9.402-21-21-21s-21,9.402-21,21C11,29.565,14.998,36.304,21,40z"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M28,45L25,25"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M36,45L39,25"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M25,26L29,29L32,26L35,29L39,26"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      width="22"
                      height="6"
                      d="M21 45 L43 45 L43 51 L21 51 Z"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      width="18"
                      height="6"
                      d="M23 51 L41 51 L41 57 L23 57 Z"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      width="14"
                      height="6"
                      d="M25 57 L39 57 L39 63 L25 63 Z"
                    ></path>
                  </svg>
                </div>
                <h2 className="learning-methods-title">Involve student</h2>
                <p className="learning-methods-description">
                  We make sure students are involved to ensure better learning
                </p>
              </div>
              <div className="learning-methods-container">
                <div className="learning-methods-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M32,12L32,32L41,41"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M4,32L8,32"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M56,32L60,32"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M32,60L32,56"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M32,8L32,4"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M32,63c17.121,0,31-13.879,31-31S49.121,1,32,1"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M32,63C14.879,63,1,49.121,1,32  c0-6.713,2.134-12.926,5.759-18l5.62-5.621"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-linejoin="bevel"
                      stroke-miterlimit="10"
                      d="M13,19L13,8L2,8"
                    ></path>
                  </svg>
                </div>
                <h2 className="learning-methods-title">regular classes</h2>
                <p className="learning-methods-description">
                  We conduct regular classes along with weekend remedial classes
                </p>
              </div>
              <div className="learning-methods-container">
                <div className="learning-methods-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <g>
                      <path
                        fill="none"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        width="48"
                        height="34"
                        d="M8 12 L56 12 L56 46 L8 46 Z"
                      ></path>
                      <path
                        fill="none"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        width="62"
                        height="6"
                        d="M1 46 L63 46 L63 52 L1 52 Z"
                      ></path>
                      <path
                        fill="none"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        d="M34,16L30,16"
                      ></path>
                    </g>
                  </svg>
                </div>
                <h2 className="learning-methods-title">individual attention</h2>
                <p className="learning-methods-description">
                  We make sure every student is individually analysed and teach
                  accordingly.
                </p>
              </div>
              <div className="learning-methods-container">
                <div className="learning-methods-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 64 64"
                  >
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M7,0L7,64"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M32.062,6L26,11L26,35L57,35L51,23L57,11L26,11"
                    ></path>
                    <path
                      fill="none"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M26,30L7,30L7,6L32,6L32,11"
                    ></path>
                  </svg>
                </div>
                <h2 className="learning-methods-title">alpha training</h2>
                <p className="learning-methods-description">
                  Students are trained with higher order thinking skills & out
                  of box knowledge
                </p>
              </div>
            </div>
          </section>

          <section className="courses-offered-section" id="courses-offered">
            <h2 className="courses-offered-heading">Our programs</h2>
            <div className="courses-offered pt-mid pb-mid">
              <div className="courses-offered-container">
                <img
                  src={School_1}
                  alt="class_1"
                  className="courses-offered-banner"
                />
                <div className="courses-offered-content-container">
                  <h2 className="courses-offered-title">Learn Basics</h2>
                  <div className="courses-offered-description">
                    Classes 1-4 are initial stages of learning we make sure that
                    basics are strong.
                  </div>
                  <div className="courses-offered-syllabus">
                    <ul>
                      <li>
                        <BsCheckCircle /> ICSE
                      </li>
                      <li>
                        <BsCheckCircle /> CBSE
                      </li>
                      <li>
                        <BsCheckCircle /> State Board
                      </li>
                    </ul>
                  </div>
                  <div className="courses-offered-tag">class 1 to 4</div>
                  <a href="contact#info" className="courses-offered-button">
                    Register now <BsArrowRight />
                  </a>
                </div>
              </div>

              <div className="courses-offered-container">
                <img
                  src={School_2}
                  alt="class_2"
                  className="courses-offered-banner"
                />
                <div className="courses-offered-content-container">
                  <h2 className="courses-offered-title">Learn Concepts</h2>
                  <div className="courses-offered-description">
                    Classes 5 to 8 cocenptual learning is important, we ensure
                    concepts are well delivered
                  </div>
                  <div className="courses-offered-syllabus">
                    <ul>
                      <li>
                        <BsCheckCircle /> ICSE
                      </li>
                      <li>
                        <BsCheckCircle /> CBSE
                      </li>
                      <li>
                        <BsCheckCircle /> State Board
                      </li>
                    </ul>
                  </div>
                  <div className="courses-offered-tag">class 5 to 7</div>
                  <a href="contact#info" className="courses-offered-button">
                    Register now <BsArrowRight />
                  </a>
                </div>
              </div>
            </div>
            <div className="courses-offered pb-bigX">
              <div className="courses-offered-container">
                <img
                  src={School_3}
                  alt="class_3"
                  className="courses-offered-banner"
                />
                <div className="courses-offered-content-container">
                  <h2 className="courses-offered-title">Involve</h2>
                  <div className="courses-offered-description">
                    Class 9 is mostly the basic to class 10 so we involve
                    students for better understanding
                  </div>
                  <div className="courses-offered-syllabus">
                    <ul>
                      <li>
                        <BsCheckCircle /> ICSE
                      </li>
                      <li>
                        <BsCheckCircle /> CBSE
                      </li>
                      <li>
                        <BsCheckCircle /> State Board
                      </li>
                    </ul>
                  </div>
                  <div className="courses-offered-tag">class 8 & 9</div>
                  <a href="contact#info" className="courses-offered-button">
                    Register now <BsArrowRight />
                  </a>
                </div>
              </div>

              <div className="courses-offered-container">
                <img
                  src={School_4}
                  alt="class_4"
                  className="courses-offered-banner"
                />
                <div className="courses-offered-content-container">
                  <h2 className="courses-offered-title">Achieve Goals</h2>
                  <div className="courses-offered-description">
                    Class 10 being important part we analyse each student and
                    train them accordingly
                  </div>
                  <div className="courses-offered-syllabus">
                    <ul>
                      <li>
                        <BsCheckCircle /> ICSE
                      </li>
                      <li>
                        <BsCheckCircle /> CBSE
                      </li>
                      <li>
                        <BsCheckCircle /> State Board
                      </li>
                    </ul>
                  </div>
                  <div className="courses-offered-tag">class 10</div>
                  <a href="contact#info" className="courses-offered-button">
                    Register now <BsArrowRight />
                  </a>
                </div>
              </div>
            </div>
            <svg
              className="shape-filler"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <path d="M 50 0 S75 0 100 100 L100 0"></path>
            </svg>
          </section>

          <section className="pt-small pb-mid">
            <h2 className="metric-title">let the numbers speak</h2>
            <div className="metric">
              <div className="metric-container">
                <div className="metric-icon">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 80.13 80.13"
                  >
                    <g>
                      <path
                        d="M48.355,17.922c3.705,2.323,6.303,6.254,6.776,10.817c1.511,0.706,3.188,1.112,4.966,1.112
		c6.491,0,11.752-5.261,11.752-11.751c0-6.491-5.261-11.752-11.752-11.752C53.668,6.35,48.453,11.517,48.355,17.922z M40.656,41.984
		c6.491,0,11.752-5.262,11.752-11.752s-5.262-11.751-11.752-11.751c-6.49,0-11.754,5.262-11.754,11.752S34.166,41.984,40.656,41.984
		z M45.641,42.785h-9.972c-8.297,0-15.047,6.751-15.047,15.048v12.195l0.031,0.191l0.84,0.263
		c7.918,2.474,14.797,3.299,20.459,3.299c11.059,0,17.469-3.153,17.864-3.354l0.785-0.397h0.084V57.833
		C60.688,49.536,53.938,42.785,45.641,42.785z M65.084,30.653h-9.895c-0.107,3.959-1.797,7.524-4.47,10.088
		c7.375,2.193,12.771,9.032,12.771,17.11v3.758c9.77-0.358,15.4-3.127,15.771-3.313l0.785-0.398h0.084V45.699
		C80.13,37.403,73.38,30.653,65.084,30.653z M20.035,29.853c2.299,0,4.438-0.671,6.25-1.814c0.576-3.757,2.59-7.04,5.467-9.276
		c0.012-0.22,0.033-0.438,0.033-0.66c0-6.491-5.262-11.752-11.75-11.752c-6.492,0-11.752,5.261-11.752,11.752
		C8.283,24.591,13.543,29.853,20.035,29.853z M30.589,40.741c-2.66-2.551-4.344-6.097-4.467-10.032
		c-0.367-0.027-0.73-0.056-1.104-0.056h-9.971C6.75,30.653,0,37.403,0,45.699v12.197l0.031,0.188l0.84,0.265
		c6.352,1.983,12.021,2.897,16.945,3.185v-3.683C17.818,49.773,23.212,42.936,30.589,40.741z"
                      />
                    </g>
                  </svg>
                </div>

                <p
                  className="metric-count"
                  id="metric-count"
                  data-target="1000"
                >
                  1000+
                </p>
                <h3 className="metric-description">students enrolled</h3>
              </div>

              <div className="metric-container">
                <div className="metric-icon">
                  <svg
                    id="Capa_1"
                    enable-background="new 0 0 511.951 511.951"
                    viewBox="0 0 511.951 511.951"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m250.702 240.02c3.307 1.226 7.008 1.312 10.547 0l189.727-71.147v164.026c4.818-1.212 9.811-1.926 15-1.926s10.182.714 15 1.926v-175.277l21.273-7.603c12.923-4.858 12.948-23.228 0-28.096l-241-90c-3.399-1.26-7.149-1.26-10.547 0l-241 90c-12.923 4.858-12.948 23.228 0 28.096z" />
                      <path d="m465.975 360.972c-25.649 0-45 32.241-45 75v30c0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-30c0-42.759-19.35-75-45-75z" />
                      <path d="m411.121 215.861-139.34 52.255c-10.437 3.855-21.473 3.753-31.509.029l-139.442-52.286c-12.512 45.854-9.33 75.947-9.855 85.113 0 10.611 10.785 17.856 20.552 13.931.352-.132 35.42-13.931 69.448-13.931 31.655 0 65.317 26.455 65.654 26.733 5.364 4.268 13.171 4.408 18.72-.015 9.288-7.441 39.185-26.719 65.626-26.719 34.028 0 69.097 13.799 69.448 13.931 9.744 3.916 20.552-3.3 20.552-13.931-.503-8.795 2.584-39.531-9.854-85.11z" />
                    </g>
                  </svg>
                </div>

                <p
                  className="metric-count"
                  id="metric-count"
                  data-target="1000clear"
                >
                  100%
                </p>
                <h3 className="metric-description">results</h3>
              </div>
              <div className="metric-container">
                <div className="metric-icon">
                  <svg
                    id="Capa_1"
                    enable-background="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="256" cy="260" r="50" />
                    <path d="m502.666 204.174c-12.445-12.445-32.622-12.445-45.067 0l-131.072 131.072c-3.984 3.984-9.388 6.223-15.022 6.223h-111.01c-5.635 0-11.038-2.238-15.022-6.223l-131.072-131.072c-12.445-12.445-32.622-12.445-45.067 0-12.445 12.445-12.445 32.622 0 45.067l151.065 151.065v111.031l191.203.663v-111.694l151.065-151.065c12.444-12.445 12.444-32.622-.001-45.067z" />
                    <path d="m203.138 110.662-7.459 43.491c-.965 5.627 1.349 11.314 5.968 14.671 4.618 3.354 10.742 3.799 15.797 1.142l39.057-20.534 39.057 20.534c2.195 1.153 4.592 1.723 6.979 1.723 3.11 0 6.205-.966 8.818-2.864 4.619-3.356 6.933-9.044 5.968-14.671l-7.459-43.491 31.598-30.801c4.089-3.985 5.561-9.946 3.796-15.376-1.764-5.431-6.458-9.389-12.108-10.209l-43.668-6.346-19.531-39.57c-2.527-5.12-7.741-8.361-13.451-8.361s-10.924 3.241-13.451 8.361l-19.528 39.569-43.668 6.346c-5.65.82-10.345 4.778-12.108 10.209-1.765 5.43-.293 11.391 3.796 15.376z" />
                  </svg>
                </div>

                <p
                  className="metric-count"
                  id="metric-count"
                  data-target="1000"
                >
                  100+
                </p>
                <h3 className="metric-description">subject toppers</h3>
              </div>
            </div>
          </section>

          <section className="pt-mid">
            <div className="carousel-content">
              <h2 className="carousel-title">students testimonial</h2>
              <div className="carousel-slider">
                <Carousel
                  itemsToScroll={1}
                  itemsToShow={window.screen.width >= 600 ? 3 : 1}
                  showArrows={false}
                >
                  {this.list.map((val) => {
                    return (
                      <Card
                        img={val.img}
                        des={val.des}
                        name={val.name}
                        cgpa={val.cgpa}
                      />
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}
