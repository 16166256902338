import React, { Component } from "react";
import TextTransition, { presets } from "react-text-transition";
import Navbar from "../components/navbar"
import Footer from '../components/footer'
import Helmet from 'react-helmet'
import Default from '../asset/about/default.svg'
import Founder1 from '../asset/about/founder1.jpeg'
import Founder2 from '../asset/about/founder2.jpeg'

const TEXTS = ["learn", "gain"];

export default class about extends Component {
    interval = 0;
    constructor(props) {
        super(props);

        this.state = {
            index: 0,
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({ index: this.state.index + 1 });

        }, 3000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        return (
            <>
                <Navbar />
                <Helmet>
          <title>Parvathi Tutorials | About</title>
        </Helmet>
                <header>
                    <div className="about-banner">
                        <div className="about-banner-text-container">
                            <h2 className="about-banner-title">
                                Expand Your Possibilities Extensively Intensively{" "}
                                <span>
                                    {" "}
                                    <TextTransition
                                        text={TEXTS[this.state.index % TEXTS.length]}
                                        springConfig={presets.wobbly}
                                    />
                                </span>
                            </h2>
                        </div>
                    </div>
                    <svg
                        className="about-banner-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        height="100"
                    >
                        <path

                            d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"
                        ></path>
                    </svg>
                </header>
                <main>
                    <section className="about-backdrop">
                        <div className="about">
                            <h3 className="about-sub-title">
                                Get to know about <span>Parvathi tutorials</span>
                            </h3>
                            <h2 className="about-title">
                                Learn about our <br />
                                <span>Work Culture</span> at Parvathi tutorials
              </h2>
                            <p className="about-paragraph">
                                Today, Parvathi tutorial is the leading educationist in
                                providing academic aid. In the year 2011, we the passionate
                                educators, fascinated by the idea of discovering our success
                                through others' success. So, our priority became to help our
                                students to discover their untouched abilities, ignite their
                                mind to strive for excellence by nurturing their potential to
                reach the peak of success along with us. <br />
                It may sound crazy but, to build the name and fame in this
                competitive world there is no better way than to lead the
                student by being among them, by going to their level, understand
                the need of each one. We believe that each child is unique. To
                make them feel better, we discover new learning possibilities.{" "}
                                <br /> We are your academic aid to meet your needs. We focus on
                maximizing the student’s potential through motivation and
                inspiration, and especially with individual care.
                <br />
                This is the right place where our best teachers make you taste
                success through progressive approach. We make our knowledge a
                boon to celebrate your success. Come let's teach, let's excel
                our lives because alone grades are not enough for life.
              </p>
                        </div>
                    </section>

                    <section className="pt-mid testimonial">
                        <div className="testimonial-teach">
                            <h2 className="testimonial-heading">
                                Our <span>teachers'</span> verbatim
                            </h2>
                            <div className="testimonial-card ">
                                {/* <img className="testimonial-img mr-mid" src={Default} alt="teacher pic" /> */}
                                <div className="testimonial-context">
                                    <h2 className="testimonial-title">
                                        Always upto  <br /><span>date with </span> concepts
                                    </h2>
                                    <p className="testimonial-description">
                                    I have been working in Parvathi tutorial for 6 years. As a teacher, it is more important for us to be updated. This has been possible for me because of the number of training sessions conducted in this institute on handling technology in the classroom. The most important thing here is flexibility, we are allowed to make children explore the things and learn instead of rote learning.
                                    </p>

                                    <p className="testimonial-name">
                                    -Nagarathna
                                    </p>
                                </div>
                            </div>
                            <div className="testimonial-card mt-bigX">
                                <div className="testimonial-context mr-mid ">
                                    <h2 className="testimonial-title">
                                        Better bond  <br /><span>with </span> students
                                    </h2>
                                    <p className="testimonial-description">
                                    The friendly bonding with students gave opportunity to gel with students, understand that students their feelings concern what is their strength, weakness, what they want to learn. It is a task as teachers to find what motivates each student to reach the pinnacle of their ability. In the same way PARVATHI TUTORIAL we always find  a way to encourage, motivate, facilitate with all the support. 
                                    </p>

                                    <p className="testimonial-name txt-align-right">
                                    -Mamtha S
                                    </p>
                                </div>
                                {/* <img className="testimonial-img " src={Default} alt="teacher pic" /> */}

                            </div>
                        </div>
                        <div className="testimonial-svg-container" >
                            <svg className="testimonial-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" height="315">
                                <path d="M 50 0 S75 0 100 100 L100 0"></path>
                            </svg>
                        </div>
                    </section>

                    <section className="pt-small pb-big">
                        <h2 className="founders-title">
                            Whose <span>Inspirations</span> You Love
                            </h2>
                        <div className="founders">
                            <div className="founders-card">
                                <div className="founders-img-container">
                                    <img src={Founder1} alt="" className="founders-img" />

                                </div>
                                <h2 className="founders-name">Parvathi Byraian</h2>
                                <h4 className="founders-designation">
                                Founder
                                    </h4>
                            </div>
                            <div className="founders-card">
                                <div className="founders-img-container">
                                    <img src={Founder2} alt="" className="founders-img" />

                                </div>
                                <h2 className="founders-name">Bhargava G</h2>
                                <h4 className="founders-designation">
                                Managing director
                                    </h4>
                            </div>
                          

                        </div>

                    </section>
                </main>
                <Footer/>
            </>
        );
    }
}
