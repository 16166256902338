import React, { Component } from 'react'
import Navbar from "../components/navbar"
import Footer from '../components/footer'
import Helmet from 'react-helmet'
import PhotoAlbum from "react-photo-album";
import Pic1 from "../asset/gallery/1.webp"
import Pic2 from "../asset/gallery/2.webp"
import Pic3 from "../asset/gallery/3.webp"
import Pic4 from "../asset/gallery/4.webp"
import Pic5 from "../asset/gallery/5.webp"
import Pic6 from "../asset/gallery/6.webp"
import Pic7 from "../asset/gallery/7.webp"
import Pic8 from "../asset/gallery/8.webp"
import Video from "../asset/gallery/video.m4v"
import "../css/video.css"
const photos = [
    {
        src: Pic1,
        width: 800,
        height: 600
    },
    {
        src: Pic3,
        width: 1600,
        height: 900
    },
    {
        src: Pic4,
        width: 400,
        height: 200
    },
    {
        src: Pic2,
        width: 1200,
        height: 800
    },
    {
        src: Pic5,
        width: 800,
        height: 600
    },
    {
        src: Pic6,
        width: 900,
        height: 450
    },
    {
        src: Pic7,
        width: 1600,
        height: 900
    },
    {
        src: Pic8,
        width: 1600,
        height: 900
    },
];
export default class Gallery extends Component {

    
  render() {
    return (
      <>
 <Navbar />
                <Helmet>
          <title>Parvathi Tutorials | About</title>
        </Helmet>
       <h3 className="parvithiTutorialsTitle">Why us?</h3>
        <video autoPlay={true} loop={true} id="parvithiTutorialsVideo" controls>
  <source  src={Video} type="video/mp4" />
  Your browser does not support HTML video.
</video>
            <h3 className="parvithiTutorialsTitle">Our wonderful moments</h3>
        <PhotoAlbum layout="rows"  photos={photos}/>

<Footer />
      </>
    )
  }
}
