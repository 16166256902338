import React, { Component } from "react";

export default class accordion extends Component {
    data = [
        {
            question: "1. Which classes and syllabus do you coach /offer?",
            ans: "Parvathi tutorial offers tuition for classes 1 - 10 of C.B.S.E, State and ICSE streams/syllabus.",
        },
        {
            question: "2. What are the qualifications of teachers at your institute?",
            ans: "Teachers at Parvathi  tutorial are highly qualified with great experience and rich in knowledge and resources in their particular subjects.",
        },
        {
            question:
                "3. Can you throw some light on the timetable? / How much weightage do you give for each subject?",
            ans: `For class IX & X  - Math- 05, Science -04, Social  Science - 04, Languages on demand.
For class 1 to 8 - Math -04, Science-03, Social Science -03, Language -03.
    `,
        },
        {
            question: "4. How do you handle or bridge the pupils of different IQ?",
            ans: `Parvathi Tutorial analyze and identify the ability of each student and each student is trained accordingly,   above average students are trained with HOT ability to reach the peak of results, the below average students are trained by reaching their level to reach at least 70%. Special remedial classes are conducted on every weekend for only those students. `,
        },
        {
            question: "5. What are the results you have achieved ?",
            ans: "In the board exams 80% + students have passed out with A1 grade. Our minimum result is 72% till now.",
        },
        {
            question: "6. Why should we opt for a Parvathi tutorial for our child?",
            ans: `* Teachers are highly qualified, experienced and reachable to every student.
* Individual attention is given to Each and every student.
* Students are  not only focused on Academic skills but are trained beyond the academic skills.
*  At Parvathi Tutorial teachers are friendly in nature to provide a comfortable zone to the students. At the same time they are very much firm towards academic  activities.
    `,
        },
        {
            question: "7. What are the methodologies followed at your tutorial?",
            ans: "Worksheet, Interaction based learning, Brainstorming, presentations by students, lecture method using projectors, mind map, periodic test and using different teaching tools.",
        },
        {
            question: "8. How do you involve parents in children's growth ?",
            ans: `* Parvathi Tutorial believes that  Parents are our major stakeholders to reach our goal.
* We encourage the parents to  have constant communication, voluntary interest and support to make a positive impact on students.
* Regular PTM is conducted.
 `,
        },
        {
            question: "9. What do Parvathi tutorial expect from parents ?",
            ans: `* We expect you to be an inspiration or  motivator and friend of your ward.
* Provide a pleasant atmosphere to enhance their learning.
* constantly monitor tutorial work.
* Payment of Fees on time.
`,
        },
        {
            question: "10. How safe is my child at your tutorial ?",
            ans: `* Your ward is 100% secure inside the Tutorial.
* Parents are frequently updated with attendance, performance and achievement of your ward. 
`,
        },
    ];

    render() {
        return (
            <>
                {this.data.map((val, key) => {
                    return (
                        <div className="accordion">
                            <input
                                type="checkbox"
                                name="accordion"
                                id={`accordion-faq-${key}`}
                                defaultChecked
                                style={{ display: "none" }}
                            />
                            <label
                                className={`accordion-label faq ${this.props.border} `}
                                htmlFor={`accordion-faq-${key}`}
                            >
                                {val.question} <span></span>
                            </label>
                            <div className="accordion-container" id="accordion">
                                <p className="subject-syllabus">{val.ans}</p>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }
}
